import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { Public } from '@material-ui/icons';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialog(props) {

  const [open, setOpen] = React.useState(false);
  const { onClose, onOpen, titoloDialog, contenutoDialog, ref } = props;

  const handleClickOpen = () => {
    setOpen(true);
    onOpen();
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  
  return (
    <div>
      <Button id='btnApriDialog' variant="outlined" style={{display: 'none'}} color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth='800px'>
        <DialogTitle id="customized-dialog-title" variant='subtitle1' onClose={handleClose}>
          {titoloDialog}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {contenutoDialog[0]}
          </Typography>  
          <Typography gutterBottom>
            {contenutoDialog[1]}
          </Typography>
          <Typography gutterBottom>
            {contenutoDialog[2]}
          </Typography>       
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
           CHIUDI
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
CustomizedDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    titoloDialog: PropTypes.string.isRequired,
    contenutoDialog: PropTypes.array.isRequired,
    ref: PropTypes.func.isRequired
};
